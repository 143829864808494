import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { StaticImage } from 'gatsby-plugin-image'
import './styles.scss'

const Section = loadable(() =>
    import('/src/components/Structure/Section/Section')
)

const ZeroSearchResults = ({ searchTerm, title }) => {
    return (
        <Section className={'c-zero-search-results'}>
            <StaticImage
                src={'../../../../images/lady-shrug.png'}
                width={400}
                alt={'Illustration of woman shrugging'}
            />

            <h2 className="c-zero-search-results__title">
                Sorry, we found <span>0</span> {title} results for{' '}
                <span>{searchTerm}</span>
            </h2>

            <p>
                Please check spelling, try using different keywords, or navigate
                via the categories listed below.
            </p>
        </Section>
    )
}

export default ZeroSearchResults

ZeroSearchResults.propTypes = {
    searchTerm: PropTypes.string,
    title: PropTypes.string
}

ZeroSearchResults.defaultProps = {
    search: 'insurance',
    title: 'Search'
}
